import React, { Fragment, useContext } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import styles from "./template-cards-title.styles";
import { SUGGESTED_CATEGORY_ID } from "../../../app.constants";
import { AppContext } from "../../../app.context";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../../lib/feature-flags";

const useStyles = makeStyles(() => styles);
const TemplateCardsTitle = () => {
  const { t } = useTranslation();
  const {
    isSearching,
    selectedCategoryTemplates,
    isSearchData,
    isLoadingCategories,
    categoryName,
    searchTerm,
    categoryId,
  } = useContext(AppContext);
  const classes = useStyles();
  const filterTitle = t("All markets");
  const categoryTitle = categoryName ? t("markets") : filterTitle;
  let searchTitle = t("Searching for");
  if (isSearchData && !isSearching) {
    const searchSuffix = selectedCategoryTemplates?.length === 1 ? t("market found for") : t("markets found for");
    searchTitle = `${selectedCategoryTemplates?.length || 0 + ""} ${searchSuffix}`;
  }
  return (
    <div className={classNames(classes.cardsTitleContainer)}>
      {!isLoadingCategories && (
        <Fragment>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              {!isSearchData && (
                <TypographyComponent styling={"h5"}>
                  {!!categoryName && `${categoryName} `}
                  {categoryId !== SUGGESTED_CATEGORY_ID && categoryTitle}
                </TypographyComponent>
              )}
              {isSearchData && (isSearching || selectedCategoryTemplates?.length > 0) && (
                <TypographyComponent styling={"h5"}>
                  <TypographyComponent element={"span"} color={"iron"}>
                    {searchTitle}
                  </TypographyComponent>
                  <TypographyComponent element={"span"} color={"carbon"}>
                    {searchTerm}
                  </TypographyComponent>
                </TypographyComponent>
              )}
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              {!isSearchData && (
                <h5>
                  {!!categoryName && `${categoryName} `}
                  {categoryId !== SUGGESTED_CATEGORY_ID && categoryTitle}
                </h5>
              )}
              {isSearchData && (isSearching || selectedCategoryTemplates?.length > 0) && (
                <h5>
                  <span className={classes.searchTitle}>{searchTitle} </span>
                  <span className={classes.searchTitleTerm}>{searchTerm}</span>
                </h5>
              )}
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </Fragment>
      )}
    </div>
  );
};

export default TemplateCardsTitle;
