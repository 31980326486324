import { getAsyncRequest } from "gx-npm-lib";
import { reportFullStorySearchEvent, storeFullStorySearchBrowseEvent } from "./full-story-events.lib";
import { Template } from "../app.types";
import { TemplateSearchRequestDTO } from "./types";

/** deprecated */
const getTemplatesFromSearchQuery = async (
  config: TemplateSearchRequestDTO,
  onSuccess: (response: Template[]) => void,
  onError: (error: unknown) => void,
  onComplete: () => void
) => {
  const qParams = [`q=${encodeURIComponent(config.searchTerm)}`];
  if (config.includeMocks) {
    qParams.push(`includeMocks=${config.includeMocks}`);
  }
  const url = `/api/v2/data/template/search?${qParams.join("&")}`;
  const response = await getAsyncRequest(url);
  if (response.status === 200 && Array.isArray(response.data?.data)) {
    const results = response.data.data;
    reportFullStorySearchEvent({ ...config, resultsCount: results.length });
    storeFullStorySearchBrowseEvent({
      searchResultsCount: results.length,
      searchTerm: config.searchTerm,
      isSearchData: true,
      categoryId: 0,
      categoryName: "",
    });
    onSuccess(results);
  } else {
    onError(response || "ERROR");
  }
  onComplete();
};

export { getTemplatesFromSearchQuery };
