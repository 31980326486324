import React, { ChangeEvent, KeyboardEvent, useCallback, useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { InputAdornment, makeStyles, TextField } from "@material-ui/core";
import { IconButton, TooltipV2, useFeatureFlag } from "gx-npm-ui";
import ClearSearchButtonWithTooltip from "./clear-search-button/clear-search-button-with-tooltip.component";
import styles from "./template-search-input.styles";
import { AppContext } from "../../../../../app.context";
import MagnifyGlassIcon from "../../../../../assets/icons/magnify-glass.icon";
import { getAsyncRequest, useCaptureEventsV2, useUserState } from "gx-npm-lib";
import { GCOM_3606__fontUpdate, GCOM_3655_improveTemplateSearch } from "../../../../../lib/feature-flags";
import { reportFullStorySearchEvent, storeFullStorySearchBrowseEvent } from "../../../../../lib/full-story-events.lib";
import { ClientEvent } from "../../../../../app.constants";
import { getTemplatesFromSearchQuery } from "../../../../../lib/get-templates.lib";

const useStyle = makeStyles(() => styles);

const MIN_SEARCH_CHAR_COUNT = 2;
const SEARCH_API_DEBOUNCE_TIME = 550;

const TemplateSearchInput = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const inputRef = useRef<HTMLElement>();
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const { hashedUserId, isGartnerAssociate, isEntitled } = useUserState();
  const captureEvents = useCaptureEventsV2();
  const isGCOM3655FF = useFeatureFlag(GCOM_3655_improveTemplateSearch);
  const isFontUpdateFFOn = useFeatureFlag(GCOM_3606__fontUpdate);
  const {
    templateData,
    setIsSearching,
    setIsError,
    setCategoryName,
    setCategoryId,
    setSelectedCategoryTemplates,
    setIsSearchData,
    setSearchTerm,
  } = useContext(AppContext);

  const onClearSearch = () => {
    switchToCategory();
    setInputValue("");
    setSearchTerm("");
    setIsSearchDisabled(true);
    inputRef?.current?.focus();
  };

  const switchToSearch = useCallback(() => {
    if (isSearchDisabled) {
      return;
    }
    setIsSearching(true);
    setIsSearchData(true);
    setSearchTerm(inputValue);
    setCategoryName("");
    setSelectedCategoryTemplates([]);
  }, [
    inputValue,
    isSearchDisabled,
    setCategoryName,
    setIsSearchData,
    setIsSearching,
    setSearchTerm,
    setSelectedCategoryTemplates,
  ]);

  const switchToCategory = useCallback(() => {
    setCategoryId(0);
    setCategoryName("");
    setSelectedCategoryTemplates(templateData);
    setIsSearchData(false);
    setIsSearching(false);
  }, [setCategoryId, setCategoryName, setIsSearchData, setIsSearching, setSelectedCategoryTemplates, templateData]);

  const onSearch = useCallback(async () => {
    if (isSearchDisabled) {
      return;
    }
    const config = {
      hashedUserId: hashedUserId,
      isEntitled: isEntitled,
      searchTerm: inputValue,
      includeMocks: isGartnerAssociate,
    };

    if (isGCOM3655FF) {
      const qParams = [`q=${encodeURIComponent(config.searchTerm)}`];
      if (config.includeMocks) {
        qParams.push(`includeMocks=${config.includeMocks}`);
      }
      const url = `/api/v3/data/template/search?${qParams.join("&")}`;
      const response = await getAsyncRequest(url);
      if (response.status === 200 && Array.isArray(response.data?.data)) {
        const results = response.data.data;
        reportFullStorySearchEvent({ ...config, resultsCount: results.length });
        storeFullStorySearchBrowseEvent({
          searchResultsCount: results.length,
          searchTerm: config.searchTerm,
          isSearchData: true,
          categoryId: 0,
          categoryName: "",
        });

        if (results.length > -1) {
          captureEvents([
            {
              eventType: ClientEvent.MARKETS_SEARCH_BY_KEYWORD,
              metaData: {
                isEntitled,
                searchTerm: config.searchTerm.toLocaleLowerCase(),
                resultsCount: results.length,
              },
            },
          ]);
        }
        setSelectedCategoryTemplates(results);
      } else {
        setIsError(true);
      }
      setIsSearching(false);
    } else {
      await getTemplatesFromSearchQuery(
        config,
        (response) => {
          if (response.length > -1) {
            captureEvents([
              {
                eventType: ClientEvent.MARKETS_SEARCH_BY_KEYWORD,
                metaData: {
                  isEntitled,
                  searchTerm: config.searchTerm.toLocaleLowerCase(),
                  resultsCount: response.length,
                },
              },
            ]);
          }
          setSelectedCategoryTemplates(response);
        },
        () => {
          setIsError(true);
        },
        () => {
          setIsSearching(false);
        }
      );
    }
  }, [
    captureEvents,
    hashedUserId,
    inputValue,
    isEntitled,
    isGartnerAssociate,
    isSearchDisabled,
    setIsError,
    setIsSearching,
    setSelectedCategoryTemplates,
    isGCOM3655FF,
  ]);

  const handleChange = (event: ChangeEvent) => {
    const { value } = event.target as HTMLInputElement;
    if (!value) {
      onClearSearch();
    }
    setInputValue(value);
    setIsSearchDisabled(value.trim().length < 1 || value.trim().length > 240);
  };

  const handleKeyDown = async (event: KeyboardEvent) => {
    if (isFocused && event.key === "Escape") {
      onClearSearch();
    } else if (isFocused && event.key === "Enter") {
      switchToSearch();
      await onSearch();
    }
  };

  useEffect(() => {
    if (inputValue.length < MIN_SEARCH_CHAR_COUNT) {
      switchToCategory();
    } else {
      switchToSearch();
      const searchTimeOut = setTimeout(onSearch, SEARCH_API_DEBOUNCE_TIME);
      return () => clearTimeout(searchTimeOut);
    }
  }, [
    onSearch,
    inputValue,
    setCategoryId,
    setCategoryName,
    setIsSearchData,
    setSelectedCategoryTemplates,
    switchToCategory,
    templateData,
    switchToSearch,
  ]);

  return (
    <div className={classNames(classes.container, "gx-search-input-container")}>
      <div
        className={classNames(
          classes.inputContainer,
          isHovered && "hovered",
          isFocused && "focused",
          !!inputValue && "populated"
        )}
      >
        <TextField
          className={classNames(classes.inputField, !!inputValue && "has-search-term")}
          fullWidth={true}
          inputProps={{ maxLength: 240 }}
          InputProps={{
            disableUnderline: true,
            classes: {
              input: isFontUpdateFFOn ? classes.fontUpdate : "",
            },
            endAdornment: (
              <InputAdornment position="end">
                {!!inputValue && <ClearSearchButtonWithTooltip onClearSearch={onClearSearch} />}
              </InputAdornment>
            ),
          }}
          inputRef={inputRef}
          onBlur={() => setIsFocused(false)}
          onFocus={() => setIsFocused(true)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          placeholder={t("Search by keyword or vendor")}
          type="text"
          value={inputValue}
        />
      </div>
      <TooltipV2
        deactivate={isSearchDisabled}
        enterDelay={400}
        enterNextDelay={400}
        placement="top"
        PopperProps={{ modifiers: { offset: { offset: "0, 2px" } } }}
        title={t("Search")}
      >
        <div className={classNames(classes.searchButton, !isSearchDisabled && "enabled")}>
          <IconButton ariaLabel={t("search")} disabled={isSearchDisabled} onClick={onSearch}>
            <MagnifyGlassIcon disabled={isSearchDisabled} />
          </IconButton>
        </div>
      </TooltipV2>
    </div>
  );
};
export default TemplateSearchInput;
