import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import PlaneIllustration from "../../../../assets/images/plane-illustration.svg";
import styles from "./no-template-search-results.styles";
import { AppContext } from "../../../../app.context";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";
import classNames from "classnames";

const useStyles = makeStyles(() => styles);
const NoTemplateSearchResults = () => {
  const { searchTerm } = useContext(AppContext);
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className={classes.noResultsContainer}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent boldness={"semi"} styling={"h3"}>
              <TypographyComponent element={"span"} color={"carbon"}>
                {t("We were unable to find any markets for")}
                <br />
              </TypographyComponent>
              <TypographyComponent element={"span"} color={"carbon"}>
                {searchTerm}
              </TypographyComponent>
            </TypographyComponent>

            <TypographyComponent element={"p"} rootClassName={classNames(classes.noResultsSubtitle)}>
              {t("Please try again with a different keyword or vendor name.")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>

          <FeatureFlagBooleanOff>
            <TypographyComponent boldness={"semi"} type={"h3"} rootClassName={classes.noResultsTitleContainer}>
              <span className={classes.titlePrefix}>{t("We were unable to find any markets for")}</span>
              <br />
              <span className={classes.titleTerm}>{searchTerm}</span>
            </TypographyComponent>
            <p className={classes.noResultsSubtitle}>
              {t("Please try again with a different keyword or vendor name.")}
            </p>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
      <div className={classes.airplaneContainer}>
        <img alt="" src={PlaneIllustration} />
      </div>
    </Fragment>
  );
};

export default NoTemplateSearchResults;
