import { colorPalette } from "gx-npm-common-styles";

const colorBlueBerry = colorPalette.interactions.blueBerry.hex;
const colorCarbon = colorPalette.neutrals.carbon.hex;
const colorIron = colorPalette.neutrals.iron.hex;
const colorQuartz = colorPalette.neutrals.quartz.hex;
const colorWhite = colorPalette.basic.white.hex;
const defaultCta = colorPalette.interactions.defaultCta.hex;

export const buttonStyles = {
  filterButton: {
    backgroundColor: colorWhite,
    border: "none",
    color: colorIron,
    display: "block",
    fontSize: "14px",
    height: "40px",
    letterSpacing: "0.25px",
    lineHeight: "21px",
    padding: 0,
    textAlign: "left" as const,
    width: "100%",
    "& img.gx-filter-icon": {
      // We can generate this filter value by using the following link. https://codepen.io/sosuke/pen/Pjoqqp
      filter:
        "brightness(0) saturate(100%) invert(58%) sepia(11%) saturate(38%) hue-rotate(329deg) brightness(93%) contrast(91%)",
    },
    "&:hover, &:focus": {
      color: colorCarbon,
      cursor: "pointer",
      "& $btnCont": {
        visibility: "visible",
      },
      "& svg path": {
        fill: colorCarbon,
      },
      "& img.gx-filter-icon": {
        filter:
          "brightness(0) saturate(100%) invert(3%) sepia(11%) saturate(430%) hue-rotate(349deg) brightness(92%) contrast(91%)",
      },
      "& img.gx-filter-suggest-icon": {
        filter:
          "brightness(0) saturate(100%) invert(3%) sepia(11%) saturate(430%) hue-rotate(349deg) brightness(92%) contrast(91%) drop-shadow(0px 0px 8px #000)",
      },
    },
    "&.selected, &.selected:hover, &.selected:focus": {
      color: colorBlueBerry,
      "& $btnCont": {
        backgroundColor: colorPalette.interactions.lightBerry.hex,
        visibility: "visible",
      },
      "& $btnInd": {
        backgroundColor: colorBlueBerry,
        visibility: "visible",
      },
      "& svg path": {
        fill: colorBlueBerry,
      },
      "& img.gx-filter-icon": {
        filter:
          "brightness(0) saturate(100%) invert(13%) sepia(98%) saturate(4423%) hue-rotate(212deg) brightness(98%) contrast(105%)",
      },
      "& img.gx-filter-suggest-icon": {
        filter: "none",
      },
    },
  },
  filterButtonGCOM3695: {
    "&.selected, &.selected:hover, &.selected:focus": {
      color: defaultCta,

      "& $btnInd": {
        backgroundColor: defaultCta,
      },
      "& svg path": {
        fill: defaultCta,
      },
      "& img.gx-filter-icon": {
        filter:
          "brightness(0) saturate(110%)  invert(13%) sepia(66%) saturate(5011%) hue-rotate(240deg) brightness(80%) contrast(145%);",
      },
    },
  },
  btnDisplay: {
    display: "flex",
    alignItems: "center",
  },
  btnChild: {
    display: "inline-flex",
  },
  btnIcon: {
    marginLeft: "20px",
    width: "22px",
    justifyContent: "center",
  },
  btnName: {
    paddingLeft: "12px",
    textAlign: "left" as const,
    flexGrow: 1,
  },
  btnCont: {
    backgroundColor: colorQuartz,
    height: "30px",
    margin: "0 24px 0 12px",
    textAlign: "center" as const,
    visibility: "hidden" as const,
    width: "30px",
  },
  btnCntValue: {
    margin: "auto",
  },
  btnInd: {
    height: "40px",
    visibility: "hidden" as const,
    width: "8px",
  },
};
