import { makeStyles } from "@material-ui/core";
import { FreeTrialRole, useUserState } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOn,
  FreeTrialBannerComponent,
  TypographyComponent,
  FeatureFlagBooleanOff,
} from "gx-npm-ui";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import HeaderIllustrationSVG from "../../assets/images/header-illustration.svg";
import styles from "./template-header.style";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";
import classNames from "classnames";

const useStyles = makeStyles(() => styles);
const TemplateHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { freeTrialDaysRemaining, freeTrialRole } = useUserState();
  const isTrialUser = !!freeTrialRole && Object.values(FreeTrialRole).includes(freeTrialRole);

  return (
    <Fragment>
      {isTrialUser && <FreeTrialBannerComponent daysRemaining={freeTrialDaysRemaining} freeTrialRole={freeTrialRole} />}
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"h4"} color={"white"} rootClassName={classNames(classes.container)}>
            {t("Explore market insights backed by Gartner research.")}
            <img alt={""} className={classes.cornerImage} src={HeaderIllustrationSVG} />
          </TypographyComponent>
        </FeatureFlagBooleanOn>

        <FeatureFlagBooleanOff>
          <header className={classes.container}>
            <h4 className={classes.title}>{t("Explore market insights backed by Gartner research.")}</h4>
            <img alt={""} className={classes.cornerImage} src={HeaderIllustrationSVG} />
          </header>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
    </Fragment>
  );
};

export default TemplateHeader;
